/* Variables */

:root {
    --bg-color: #F5E8E4;
    --text-color: #411530;
    --menu-color: #D1512D;
    --link-color: #853B15;
}

/* Configuration */

p {
    color: var(--text-color);
}

a {
    color: var(--menu-color);
    text-decoration: none;
    font-weight: 600;
}

a:hover {
    color: var(--link-color);
}

button {
    color: var(--text-color);
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid black;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: var(--bg-color);
}

button:hover {
    color: var(--link-color);
}

hr {
    border-top: 2px solid var(--text-color);
    opacity: 1;
    margin: 1rem auto;
}

hr.hr-short {
    width: 50%;
}

hr.hr-shorter {
    width: 25%;
}

/* Page */

.page-main {
    color: var(--text-color);
}

/* Header */

.site-header {
    background-color: var(--bg-color);
}

.profile-img {
    border: 2px solid var(--text-color);
    opacity: 0.7;
    transition: border 0.3s, opacity 0.3s;
}

.profile-img:hover {
    border-width: 2px;
    opacity: 1;
}

/* Sidebar */

.sidebar {
    height: auto;
    min-height: 100%;
}

/* Footer */

.site-footer {
    background-color: var(--bg-color);
    position: fixed;
    bottom: 0;
}